import "./App.css";
import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import NavBar from "./shared/components/NavBar";
import NotFound from "./pages/error";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import WindowWidthProvider from "./shared/components/WindowWidthContext";
const Landing = lazy(() => import('./features/landing/LandingPage'));
const DashboardContainer = lazy(() => import('./features/dashboard/DashboardContainer'));
const ProfileContainer = lazy(() => import('./features/profile/ProfileContainer'));
const RoomEditorContainer = lazy(() => import('./features/roomEditor/RoomEditorContainer'));
const GameplayContainer = lazy(() => import('./features/gameplay/GameplayContainer'));
const PreviewContainer = lazy(() => import('./features/gameplay/PreviewContainer'));
const Register = lazy(() => import('./features/auth/components/Register'));
const LogIn = lazy(() => import('./features/auth/components/Login'));
const ResetPassword = lazy(() => import('./features/auth/components/ResetPassword'));




function App() {
  const location = useLocation();
  const isLandingPage = location.pathname === '/'; // Check if the current path is the landing page

  return (
    <ThemeProvider theme={theme}>
      <WindowWidthProvider>

        <div className="App" style={{ height: "100%" }}>
          {!isLandingPage && <NavBar />}
          <Routes>
            <Route path="/" exact element={<Suspense fallback={<>Loading..</>}><Landing /></Suspense>}></Route>
            <Route path="/login" element={<Suspense fallback={<>Loading..</>}><LogIn /></Suspense>}></Route>
            <Route path="/register" element={<Suspense fallback={<>Loading..</>}><Register /></Suspense>}></Route>
            <Route path="/reset-password" element={<Suspense fallback={<>Loading..</>}><ResetPassword /></Suspense>}></Route>
            <Route path="/dashboard" exact element={<Suspense fallback={<>Loading..</>}><DashboardContainer /></Suspense>}></Route>
            <Route
              path="/room-editor/:roomId?"
              element={<Suspense fallback={<>Loading...</>}><RoomEditorContainer /></Suspense>}
            ></Route>
            <Route
              path="/preview/:roomId?"
              element={<Suspense fallback={<>Loading..</>}><PreviewContainer /></Suspense>}
            ></Route>
            <Route
              path="/gameplay/:roomId"
              element={<Suspense fallback={<>Loading..</>}><GameplayContainer /></Suspense>}
            ></Route>
            <Route path="/profile" element={<Suspense fallback={<>Loading..</>}><ProfileContainer /></Suspense>}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </div>
      </WindowWidthProvider>
    </ThemeProvider>
  );
}

export default App;
