// This file contains the Redux slice for the dashboard feature, including the fetchRooms action.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../config/firebase-config";
import { getDocs, collection, query, where } from "firebase/firestore";

// Fetch room from firestore
export const fetchRooms = createAsyncThunk("dashboard/fetchRooms", async () => {
  const roomsQuery = query(collection(db, "rooms"), where("isPublished", "==", true));
  const querySnapshot = await getDocs(roomsQuery);
  // const querySnapshot = await getDocs(collection(db, "rooms"));
  const rooms = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data(),
  }));
  console.log("Rooms fetched: ", rooms);
  return rooms;
});


const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loadedRooms: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRooms.fulfilled, (state, action) => {
      state.loadedRooms = action.payload;
    });
  },
});

export const selectLoadedRooms = (state) => state.dashboard.loadedRooms;

export default dashboardSlice.reducer;
