import { configureStore } from '@reduxjs/toolkit';
import editorReducer from './features/roomEditor/editorSlice';
import gameplayReducer from './features/gameplay/slices/gameplaySlice';
import gameStatusReducer from './features/gameplay/slices/gameStatusSlice';
import recordReducer from './shared/slices/recordSlice';
import ratingReducer from './shared/slices/ratingSlice';
import bookmarkReducer from './shared/slices/bookmarkSlice';
import dashboardReducer from './features/dashboard/DashboardSlice';
import tabReducer from './shared/slices/tabSlice';
const store = configureStore({
    reducer: {
        editor: editorReducer,
        gameplay: gameplayReducer,
        gameStatus: gameStatusReducer,
        gameRecord: recordReducer,
        bookmarks: bookmarkReducer,
        dashboard: dashboardReducer,
        tab: tabReducer,
        rating: ratingReducer,
    }})

export default store;