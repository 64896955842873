// The list of background image users can choose from.
export const backgroundLibrary = [
    {
      id: "background-1",
      title: "Background1",
      uri: "/images/background/webp/wall_01.webp",
    },
    {
      id: "background-2",
      title: "Background2",
      uri: "/images/background/webp/wall_02.webp",
    },
    {
      id: "background-3",
      title: "Background3",
      uri: "/images/background/webp/wall_03.webp",
    },
    {
      id: "background-4",
      title: "Background4",
      uri: "/images/background/webp/wall_04.webp",
    },
    {
      id: "background-5",
      title: "Background5",
      uri: "/images/background/webp/wall_05.webp",
    },
    {
      id: "background-6",
      title: "Background6",
      uri: "/images/background/webp/wall_06.webp",
    },
    {
      id: "background-7",
      title: "Background7",
      uri: "/images/background/webp/wall_07.webp",
    },
    {
      id: "background-8",
      title: "Background8",
      uri: "/images/background/webp/wall_08.webp",
    },
    {
      id: "background-9",
      title: "Background9",
      uri: "/images/background/webp/wall_09.webp",
    },
    {
      id: "background-10",
      title: "Background10",
      uri: "/images/background/webp/wall_10.webp",
    },
    {
      id: "background-11",
      title: "Background11",
      uri: "/images/background/webp/wall_11.webp",
    },
    {
      id: "background-12",
      title: "Background12",
      uri: "/images/background/webp/wall_12.webp",
    },
    {
      id: "background-13",
      title: "Background13",
      uri: "/images/background/webp/wall_13.webp",
    },
    {
      id: "background-14",
      title: "Background14",
      uri: "/images/background/webp/wall_14.webp",
    },
    {
      id: "background-15",
      title: "Background15",
      uri: "/images/background/webp/wall_15.webp",
    },
    {
      id: "background-16",
      title: "Background16",
      uri: "/images/background/webp/wall_16.webp",
    },
    {
      id: "background-17",
      title: "Background17",
      uri: "/images/background/webp/wall_17.webp",
    },
    {
      id: "background-18",
      title: "Background18",
      uri: "/images/background/webp/wall_18.webp",
    },
    {
      id: "background-19",
      title: "Background19",
      uri: "/images/background/webp/wall_19.webp",
    },
    {
      id: "background-20",
      title: "Background20",
      uri: "/images/background/webp/wall_20.webp",
    },
    {
      id: "background-21",
      title: "Background21",
      uri: "/images/background/webp/wall_21.webp",
    },
    {
      id: "background-22",
      title: "Background22",
      uri: "/images/background/webp/wall_22.webp",
    },
    {
      id: "background-23",
      title: "Background23",
      uri: "/images/background/webp/wall_23.webp",
    },
    {
      id: "background-24",
      title: "Background24",
      uri: "/images/background/webp/wall_24.webp",
    },
    {
      id: "background-25",
      title: "Background25",
      uri: "/images/background/webp/wall_25.webp",
    },
    {
      id: "background-26",
      title: "Background26",
      uri: "/images/background/webp/wall_26.webp",
    },
    {
      id: "background-27",
      title: "Background27",
      uri: "/images/background/webp/wall_27.webp",
    },
    {
      id: "background-28",
      title: "Background28",
      uri: "/images/background/webp/wall_28.webp",
    },
  ];