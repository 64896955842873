import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../config/firebase-config";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// Firestore collection structure: "ratings" collection contains documents with roomID,
// each having fields for rating counts and sum of all ratings.
// For example:
//    0MKenuGxznm1cxavXMXY: {
//      count: 3
//      sum: 10
//    }

// Add room ratings to Firestore.
// If the roomId doesn't exist in the database, add a new document using the roomId.
// Each documents stores the sum and count of all ratings.
export const addRatingToFirestore = createAsyncThunk(
  "ratings/addRatingToFirestore",
  async ({ roomId, rating }) => {
    try {
      const newRating = { roomId, rating };
      const docRef = doc(db, "ratings", `${roomId}`);
      const res = await getDoc(docRef);
      console.log(res.exists());

      if (!res.exists()) {
        console.log("No rating found");
        const initialRating = { sum: rating, count: 1 };
        await setDoc(docRef, initialRating);
      } else {
        console.log(res.data());
        const { sum, count } = res.data();

        // Add new records to records collection
        await updateDoc(docRef, { sum: sum + rating, count: count + 1 });
        console.log("Added new rating: ");
      }

      return newRating;
    } catch (err) {
      console.log("Error uploading rating: ", err);
    }
  }
);

// Fetch average ratings and count of all rooms. The average rating is calcuated during fetching.
export const fetchAverageRatingsAndCount = createAsyncThunk(
  "ratings/fetchAverageRatings",
  async () => {
    try {
      const roomRatings = {};
      const querySnapshot = await getDocs(collection(db, "ratings"));

      querySnapshot.forEach((doc) => {
        const roomId = doc.id;
        const { sum, count } = doc.data();
        roomRatings[roomId] = {
          average: sum / count,
          count: count,
        };
      });

      return roomRatings;
    } catch (err) {
      console.log("Error fetching room by room ID: ", err);
    }
  }
);

// Fetch average ratings by roomId
export const fetchAverageRatingById = createAsyncThunk(
  "ratings/fetchAverageRatingById",
  async ({ id }) => {
    try {
      console.log("rating by Room id");
      const docRef = doc(db, "ratings", `${id}`);
      const res = await getDoc(docRef);
      // Check if the document exists
      if (!res.exists()) {
        console.log("No record found for Room ID:", id);
        return 0; // Return 0 if no record exists
      }
      const data = res.data();

      const average = data.sum / data.count;

      return average;
    } catch (err) {
      console.log("Error fetching ratings by room ID: ", err);
    }
  }
);

export const ratingSlice = createSlice({
  name: "rating",
  initialState: {
    gameRatings: {},
    currentRating: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRatingToFirestore.fulfilled, (state, action) => {
        console.log("Adding rating to firestore: ", action.payload);
      })
      .addCase(fetchAverageRatingsAndCount.fulfilled, (state, action) => {
        state.gameRatings = action.payload;
        console.log("Fetching Average rating of rooms", state.gameRatings);
      })
      .addCase(fetchAverageRatingById.fulfilled, (state, action) => {
        state.currentRating = action.payload;
      });
  },
});

export const { } = ratingSlice.actions;

export const selectAverageRatings = (state) => state.rating.gameRatings;

export const selectCurrentRoomRating = (state) => state.rating.currentRating;

export default ratingSlice.reducer;
