import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  MenuItem,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "../slices/tabSlice";
import { useNavigate } from "react-router-dom";
import {
  LogInButton,
  LogOutButton,
  SignUpButton,
} from "../components/AuthButton";
import { StyledTab, StyledTabs, StyledMenu } from "./StyledComponents"
import { useState } from "react";
import { auth } from "../../config/firebase-config";

const pages = [
  {
    name: "Home",
    url: "/dashboard",
  },
  {
    name: "Projects",
    url: "/profile",
  },
];

function NavBar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isAuth, name } = useGetUserInfo();
  const { currentTab } = useSelector((state) => state.tab);

  // console.log("logged in", isAuth, name);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  console.log("current tab in nav bar: ", currentTab);

  const handleMenuClose = (e) => {
    setAnchorEl(null);
  }

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleLogInButtonClick = (e) => {
    navigate("/login")
    handleMenuClose()
  }

  const handleSignUpButtonClick = (e) => {
    navigate("/register")
    handleMenuClose()
  };

  const handleLogOutButtonClick = async (e) => {
    try {
      await auth.signOut();
      console.log("Signed out successfully");
      localStorage.clear();
      setCurrentTab("Home");
      navigate("/dashboard");
      handleMenuClose()
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  const Divider = () => {
    return (
      <Box
        sx={{
          height: "24px",
          width: "1px",
          backgroundColor: theme.palette.text.primary,
          marginRight: 2,
        }}
      />
    );
  };

  const AuthIcons = () => {
    const navigate = useNavigate();

    if (isAuth) {
      return (
        <Box sx={{ ml: "auto", display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton onClick={() => navigate("/profile")}>
            <AccountCircleOutlinedIcon
              sx={{ marginRight: 1, color: "text.primary" }}
            />
            <Typography color="text.primary" variant="body2">
              {name}
            </Typography>
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <LogOutButton variant="contained" />
        </Box>
      );
    }

    return (
      <Box sx={{ ml: "auto", display: "flex", alignItems: "center", gap: 2 }}>
        <LogInButton />
        <SignUpButton />
      </Box>
    );
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "linear-gradient(90deg, #E52D27, #B31217)" }}
    >
      <Container>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <Box sx={{ mr: 5, display: { xs: 'none', sm: "block" } }} >
              <img src={"/LOGO_white.webp"} alt="Lost Logo" width="70px" height="25px"/>
            </Box>
            < StyledTabs
              value={currentTab}
              aria-label="navbar tabs"
              onChange={(e, value) => dispatch(setCurrentTab(value))}
              textColor="inherit"
              indicatorColor="#fff"
            >
              {pages
                .filter(
                  (page) =>
                    page.name !== "Projects" ||
                    (page.name === "Projects" && isAuth)
                )
                .map((page, index) => (
                  <StyledTab
                    key={page.name}
                    value={page.name}
                    label={page.name}
                    onClick={() => navigate(page.url)}
                  />
                ))}
            </StyledTabs>
          </Box>

          <Box>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <AuthIcons />
            </Box>

            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMenuOpen}
              sx={{ display: { xs: 'block', sm: 'none' }, pr: "20px" }} // Show only on small screens
            >
              <MenuIcon />
            </IconButton>
            <StyledMenu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom', // Aligns the top of the menu with the button
                horizontal: 'left', // Aligns the right edge of the menu with the button
              }}
            >
              {!isAuth &&
                <MenuItem onClick={handleLogInButtonClick}>
                  Log In
                </MenuItem>
              }
              {!isAuth && (
                <MenuItem onClick={handleSignUpButtonClick}>
                  Sign Up
                </MenuItem>
              )}
              {isAuth && (
                <MenuItem onClick={handleLogOutButtonClick}>
                  Log Out
                </MenuItem>
              )}
            </StyledMenu>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
