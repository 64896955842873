
// Utility function to get item by ID
export const getItemById = (id) => {
  // Extract the item name from the ID (before the underscore)
  const itemName = id.split('_')[0]; // Assumes the format is "ItemName_123"
  return itemLibraryV2[itemName] || null; // Return the item or null if not found
};

export const getStatesByIdAndColor = (id, color) => {
  const itemName = id.split('_')[0]; // Assumes the format is "ItemName_123"
  return itemLibraryV2[itemName].variants[color] || null; // Return the item or null if not found
};

export const getUriByStates = (id, color, state) => {
  const item = getItemById(id);
  return item.variants[color][state]
}



// The list items available to be placed in the room.
// Each variants has their own ID according to their colour.
export const itemLibraryV2 = {
  // ---------------------------------------------------------- //
  // ---------                 Doors                 ---------- //
  // ---------------------------------------------------------- //
  "Door": {
    tag: "Doors",
    width: 15, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 26, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_1_lock2.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 2": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_2_digital.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 3": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_3_lock2.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 4": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_4_digital.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 5": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_5_lock1.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 6": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_6_lock1.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 7": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_7_lock3.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 8": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_8_lock2.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 9": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_9_lock2.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 10": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_10_digital.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 11": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_11_lock3.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 12": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_12_lock1.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 13": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_13_lock2.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 14": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_14_lock1.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },
  "Door 15": {
    tag: "Doors",
    width: 17, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 20, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/doors/webp/doors_15_lock1.webp",
      },
    },
    isContainer: false,
    isLockable: true,
  },

  // ---------------------------------------------------------- //
  // ---------                 Keys                  ---------- //
  // ---------------------------------------------------------- //
  "Simple Key": {
    tag: "Keys",
    width: 3, //in percentage
    defaultPosition: {
      x: 70, //in percentage
      y: 15, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/keys/webp/key_1.webp",

      },
      silver: {
        default: "/images/assets/keys/webp/key_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Victorian Key": {
    tag: "Keys",
    width: 3, //in percentage
    defaultPosition: {
      x: 60, //in percentage
      y: 15, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/keys/webp/key_3.webp",

      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Shining Key": {
    tag: "Keys",
    width: 3, //in percentage
    defaultPosition: {
      x: 50, //in percentage
      y: 15, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/keys/webp/key_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Antique Silver Key": {
    tag: "Keys",
    width: 3, //in percentage
    defaultPosition: {
      x: 40, //in percentage
      y: 15, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/keys/webp/key_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Antique Golden Key": {
    tag: "Keys",
    width: 3, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 15, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/keys/webp/key_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Key Card": {
    tag: "Keys",
    width: 2, //in percentage
    defaultPosition: {
      x: 70, //in percentage
      y: 20, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/keys/webp/Keycard 1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Swipe Card": {
    tag: "Keys",
    width: 2, //in percentage
    defaultPosition: {
      x: 70, //in percentage
      y: 15, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/keys/webp/Keycard 2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },

  // ---------------------------------------------------------- //
  // ---------               Storage                 ---------- //
  // ---------------------------------------------------------- //
  "Chest": {
    tag: "Storage",
    width: 15, //in percentage
    defaultPosition: {
      x: 10, //in percentage
      y: 60, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/storage/webp/box_1_off.webp",
        opened: "/images/assets/storage/webp/box_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wooden Box": {
    tag: "Storage",
    width: 8, //in percentage
    defaultPosition: {
      x: 30, //in percentage
      y: 25, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/box_2_off.webp",
        opened: "/images/assets/storage/webp/box_2_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Paper Box": {
    tag: "Storage",
    width: 13, //in percentage
    defaultPosition: {
      x: 77, //in percentage
      y: 63, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/box_3_off.webp",
        opened: "/images/assets/storage/webp/box_3_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wide Cabinet": {
    tag: "Storage",
    width: 15, //in percentage
    defaultPosition: {
      x: 15, //in percentage
      y: 53, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/drawer_1_off.webp",
        opened: "/images/assets/storage/webp/drawer_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Short Cabinet": {
    tag: "Storage",
    width: 10, //in percentage
    defaultPosition: {
      x: 30, //in percentage
      y: 62, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/drawer_2_off.webp",
        opened: "/images/assets/storage/webp/drawer_2_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tall Cabinet": {
    tag: "Storage",
    width: 8, //in percentage
    defaultPosition: {
      x: 61, //in percentage
      y: 56, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/drawer_3_off.webp",
        opened: "/images/assets/storage/webp/drawer_3_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Safe": {
    tag: "Storage",
    width: 7, //in percentage
    defaultPosition: {
      x: 70, //in percentage
      y: 15, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/storage/webp/safe_1_off.webp",
        opened: "/images/assets/storage/webp/safe_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Square Safebox": {
    tag: "Storage",
    width: 10, //in percentage
    defaultPosition: {
      x: 70, //in percentage
      y: 24, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/storage/webp/safe_2_off.webp",
        opened: "/images/assets/storage/webp/safe_2_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tall Shoe Cabinet": {
    tag: "Storage",
    width: 8, //in percentage
    defaultPosition: {
      x: 82, //in percentage
      y: 38, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/shelf_1_off.webp",
        opened: "/images/assets/storage/webp/shelf_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Shoe Cabinet": {
    tag: "Storage",
    width: 14, //in percentage
    defaultPosition: {
      x: 60, //in percentage
      y: 52, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/shelf_2_off.webp",
        opened: "/images/assets/storage/webp/shelf_2_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Display Cabinet": {
    tag: "Storage",
    width: 18, //in percentage
    defaultPosition: {
      x: 22, //in percentage
      y: 47, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/shelf_3_off.webp",
        opened: "/images/assets/storage/webp/shelf_3_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },

  "Wide Shelf": {
    tag: "Storage",
    width: 27, //in percentage
    defaultPosition: {
      x: 1, //in percentage
      y: 22, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/storage/webp/shelf_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Metal Shelf": {
    tag: "Storage",
    width: 15, //in percentage
    defaultPosition: {
      x: 71, //in percentage
      y: 28, //in percentage
    },
    variants: {
      sikver: {
        default: "/images/assets/storage/webp/shelf_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tall Shelf": {
    tag: "Storage",
    width: 8, //in percentage
    defaultPosition: {
      x: 71, //in percentage
      y: 25, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/storage/webp/shelf_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },


  // ---------------------------------------------------------- //
  // ---------             Decorations               ---------- //
  // ---------------------------------------------------------- //
  "Aquarium": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/aquarium_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wide Aquarium": {
    tag: "Decorations",
    width: 10, //in percentage
    defaultPosition: {
      x: 28, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/aquarium_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Golden Aquarium": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 39, //in percentage
      y: 10, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/decorations/webp/aquarium_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Gold Fish": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/decorations/webp/fish_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Fish": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      orange: {
        default: "/images/assets/decorations/webp/fish_2.webp",
      }
    },
    isContainer: true,
    isLockable: true,
  },
  "Office Bag": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/bag_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Traveller Bag": {
    tag: "Decorations",
    width: 8, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/bag_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Backpack": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/bag_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "School Bag": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      peru: {
        default: "/images/assets/decorations/webp/bag_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Baseball": {
    tag: "Decorations",
    width: 2, //in percentage
    defaultPosition: {
      x: 67, //in percentage
      y: 50, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/ball_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Basketball": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 67, //in percentage
      y: 50, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/ball_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "American Football": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 75, //in percentage
      y: 26, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/ball_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Casement Window": {
    tag: "Decorations",
    width: 13, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/window_1_white.webp",
      },
      wheat: {
        default: "/images/assets/decorations/webp/window_1_beige.webp",
      },
      chocolate: {
        default: "/images/assets/decorations/webp/window_3.webp"
      }
    },
    isContainer: true,
    isLockable: true,
  },
  "Hung Window": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/window_2_white.webp",
      },
      chocolate: {
        default: "/images/assets/decorations/webp/window_2_brown.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Window Blinds": {
    tag: "Decorations",
    width: 16, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/blinds_2_off.webp",
        opened: "/images/assets/decorations/webp/blinds_2_on.webp"
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Roller Blinds": {
    tag: "Decorations",
    width: 16, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/blinds_1_off.webp",
        opened: "/images/assets/decorations/webp/blinds_1_on.webp"
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Books": {
    tag: "Decorations",
    width: 12, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 10, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/books_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Calendar": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 10, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/calender_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Bug": {
    tag: "Decorations",
    width: 1, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 10, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/decorations/webp/bugs_1.webp",
      },
      yellow: {
        default: "/images/assets/decorations/webp/bugs_2.webp",
      },
      white: {
        default: "/images/assets/decorations/webp/bugs_3.webp",
      },
      green: {
        default: "/images/assets/decorations/webp/bugs_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Butterfly": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 10, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/bugs_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Candle": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/candle_1_off.webp",
        opened: "/images/assets/decorations/webp/candle_1_on.webp"
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Candy": {
    tag: "Decorations",
    width: 1, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 10, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/candy_hk.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wooden Chair": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 12, //in percentage
      y: 53, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/chair_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Couch": {
    tag: "Decorations",
    width: 14, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 53, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/chair_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Cleaner": {
    tag: "Decorations",
    width: 2, //in percentage
    defaultPosition: {
      x: 81, //in percentage
      y: 40, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/cleaner_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Clock": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 50, //in percentage
      y: 2, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/clock_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Crystal Ball with Stand": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 80, //in percentage
      y: 60, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/crystalball_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Crystal Ball": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 68, //in percentage
    },
    variants: {
      orange: {
        default: "/images/assets/decorations/webp/crystalball_2.webp",
      },
      pink: {
        default: "/images/assets/decorations/webp/crystalball_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Cup": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 40, //in percentage
      y: 15, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/cup_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Dart Board": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/dart_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Dart": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/dart_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Small Frame": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 50, //in percentage
      y: 5, //in percentage
    },
    variants: {
      yellow: {
        default: "/images/assets/decorations/webp/frame_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Baroque Frame": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 55, //in percentage
      y: 5, //in percentage
    },
    variants: {
      peru: {
        default: "/images/assets/decorations/webp/frame_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Antique Frame": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 67, //in percentage
      y: 5, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/frame_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Victorian Style Frame": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 77, //in percentage
      y: 5, //in percentage
    },
    variants: {

      brown: {
        default: "/images/assets/decorations/webp/frame_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wooden Frame": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 50, //in percentage
      y: 15, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/frame_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Frame with Gold Edging": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 58, //in percentage
      y: 15, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/frame_8.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Modern Frame": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 68, //in percentage
      y: 15, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/frame_7.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Portrait": {
    tag: "Decorations",
    width: 12, //in percentage
    defaultPosition: {
      x: 73, //in percentage
      y: 15, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/decorations/webp/frame_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Instrument": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/decorations/webp/instrument_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Lamp": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 85, //in percentage
      y: 5, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/decorations/webp/lamp_1_off.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wall Light": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 61, //in percentage
      y: 10, //in percentage
    },
    variants: {
      grey: {
        default: "/images/assets/decorations/webp/lamp_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Envelope": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 70, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/mail_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Postcard": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 21, //in percentage
      y: 80, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/mail_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Letter": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 23, //in percentage
      y: 80, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/mail_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Pen": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 31, //in percentage
      y: 70, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/pen_1.webp",
      },
      black: {
        default: "/images/assets/decorations/webp/pen_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Pencil": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 35, //in percentage
      y: 70, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/decorations/webp/pen_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Noticeboard": {
    tag: "Decorations",
    width: 9, //in percentage
    defaultPosition: {
      x: 61, //in percentage
      y: 10, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/notice_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Mirror": {
    tag: "Decorations",
    width: 12, //in percentage
    defaultPosition: {
      x: 8, //in percentage
      y: 18, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/mirror_2.webp",
      },
      gold: {
        default: "/images/assets/decorations/webp/mirror_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Mini Plant": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 8, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Orange Grass": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 14, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Grass": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 18, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tabletop Plant": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 22, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_4.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Pink Flower Plant": {
    tag: "Decorations",
    width: 4.5, //in percentage
    defaultPosition: {
      x: 28, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Sedona Sunset Plant": {
    tag: "Decorations",
    width: 4.5, //in percentage
    defaultPosition: {
      x: 33, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Big Leaf Plant": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 40, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_7.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Snake Plant": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 47, //in percentage
      y: 5, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/plants_8.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Hexagon Wall Shelf": {
    tag: "Decorations",
    width: 8, //in percentage
    defaultPosition: {
      x: 30, //in percentage
      y: 16, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/shelf_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Wall Shelf": {
    tag: "Decorations",
    width: 17, //in percentage
    defaultPosition: {
      x: 72, //in percentage
      y: 23, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/shelf_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Rectangle Wall Shelf": {
    tag: "Decorations",
    width: 17, //in percentage
    defaultPosition: {
      x: 72, //in percentage
      y: 23, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/shelf_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Skull": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 72, //in percentage
      y: 15, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/skull_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Speaker": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 77, //in percentage
      y: 14, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/speaker_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Statue": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 40, //in percentage
      y: 38, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/statue_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Table": {
    tag: "Decorations",
    width: 25, //in percentage
    defaultPosition: {
      x: 11.5, //in percentage
      y: 68, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/table_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tool": {
    tag: "Decorations",
    width: 2, //in percentage
    defaultPosition: {
      x: 40, //in percentage
      y: 55, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/decorations/webp/tool_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Bear Doll": {
    tag: "Decorations",
    width: 3, //in percentage
    defaultPosition: {
      x: 50, //in percentage
      y: 48, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/toy_1.webp",
      },
      brown: {
        default: "/images/assets/decorations/webp/toy_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Double Gourd Vase": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 20, //in percentage
      y: 40, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/vase_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Glazed Vase": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 23, //in percentage
      y: 40, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/vase_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Golden Stripe Vase": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 26, //in percentage
      y: 40, //in percentage
    },
    variants: {
      gold: {
        default: "/images/assets/decorations/webp/vase_7.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Double Handle Vase": {
    tag: "Decorations",
    width: 6, //in percentage
    defaultPosition: {
      x: 29, //in percentage
      y: 40, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/vase_3.webp",
      },

    },
    isContainer: true,
    isLockable: true,
  },
  "Wooden Vase": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 36, //in percentage
      y: 40, //in percentage
    },
    variants: {
      wheat: {
        default: "/images/assets/decorations/webp/vase_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Long Neck Vase": {
    tag: "Decorations",
    width: 2.5, //in percentage
    defaultPosition: {
      x: 39, //in percentage
      y: 40, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/vase_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - Catastrophe": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 15, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_Catastrophe.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - Cheung Po Tsai": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 17, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_CheungPoTsai.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - Nathan Road 452": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 19, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_Nathan Road 452.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - The Clone": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 21, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_TheClone.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - The Crystal Skull": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 23, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_TheCrystalSkull.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster with Frame - The Legen Of Siren": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 25, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_frame_TheLegendOfSiren.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Charles Babbage": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 27, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_babbage.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Blood in the Dark": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 29, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_bloodInTheDark.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Lost City of Gold": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 31, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_City of Gold.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Jailbreak": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 33, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_CWBPoster.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Diamond Heist": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 35, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_diamondHeist.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Enchanted Store": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 37, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_enchantedStore.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Fantasy Land": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 39, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Fantasyland.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Isekai Train": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 41, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_isekaiTrain.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Khmer Empire": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 43, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Khmer Empire.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - MI6": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 45, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_MI6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Nathan Road 452": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 47, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Nathan Road 452.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Genius Escaper": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 49, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Prison.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Sanada Yukimura": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 51, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_sanadaYukimura.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Sherlock Holmes": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 53, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Sherlock Holmes.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Greatest Show": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 55, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_The Greatest Show.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Lift": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 57, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_the_lift.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Clone": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 59, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_TheClone.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Last Scene": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 61, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_TheLastScene.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Legend of Siren": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 63, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframeTheLegendOfSiren.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The One": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 65, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_TheOne.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - The Will": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 67, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_TheWill.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Trinidad": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 69, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_Trinidad.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Wizard & Dungeon": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 71, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_noframe_WizardAndDungeon.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - 4th Cabin": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 73, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_4thCabin.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Dinothreat": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 75, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_dinothreat.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Gaming Adventure": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 77, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_gamingAdventure.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Glacial Epoch": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 79, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_glacialEpoch.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Lost in the Museum": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 81, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_lostInTheMeseum.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster - Tricky Treat": {
    tag: "Decorations",
    width: 11, //in percentage
    defaultPosition: {
      x: 83, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/lostposter_whampoa_trickytreat.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Space Poster": {
    tag: "Decorations",
    width: 8, //in percentage
    defaultPosition: {
      x: 15, //in percentage
      y: 10, //in percentage
    },
    variants: {
      purple: {
        default: "/images/assets/decorations/webp/poster_1_rocket.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Caravan Poster": {
    tag: "Decorations",
    width: 10, //in percentage
    defaultPosition: {
      x: 21, //in percentage
      y: 11, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/poster_1_sun.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Vintage Poster": {
    tag: "Decorations",
    width: 8, //in percentage
    defaultPosition: {
      x: 22, //in percentage
      y: 12, //in percentage
    },
    variants: {
      green: {
        default: "/images/assets/decorations/webp/poster_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "HK Star Ferry Poster": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 23, //in percentage
      y: 13, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/poster_hk_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "HK Ferry Poster": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 24, //in percentage
      y: 14, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/poster_hk_2.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "HK 50s Poster": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 25, //in percentage
      y: 15, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/poster_hk_3.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Lady Poster": {
    tag: "Decorations",
    width: 4, //in percentage
    defaultPosition: {
      x: 26, //in percentage
      y: 16, //in percentage
    },
    variants: {
      black: {
        default: "/images/assets/decorations/webp/poster_hk_6.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "LOST Poster": {
    tag: "Decorations",
    width: 5, //in percentage
    defaultPosition: {
      x: 27, //in percentage
      y: 17, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/decorations/webp/poster_hk_5.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "HK Poster": {
    tag: "Decorations",
    width: 10, //in percentage
    defaultPosition: {
      x: 28, //in percentage
      y: 18, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/decorations/webp/poster_hk_7.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Music Poster": {
    tag: "Decorations",
    width: 7, //in percentage
    defaultPosition: {
      x: 29, //in percentage
      y: 19, //in percentage
    },
    variants: {
      blue: {
        default: "/images/assets/decorations/webp/poster_hk_4.webp",
      }
    },
    isContainer: true,
    isLockable: true,
  },

  // ---------------------------------------------------------- //
  // -----                 Electronics                   ------ //
  // ---------------------------------------------------------- //

  // 
  "Air Conditioner": {
    tag: "Electronics",
    width: 19, //in percentage
    defaultPosition: {
      x: 1.5, //in percentage
      y: 5, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/electronics/webp/AC_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Coffee Machine": {
    tag: "Electronics",
    width: 6, //in percentage
    defaultPosition: {
      x: 23.5, //in percentage
      y: 56, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/electronics/webp/coffeemaker_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Dish Washer": {
    tag: "Electronics",
    width: 11.5, //in percentage
    defaultPosition: {
      x: 60, //in percentage
      y: 60, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/electronics/webp/dishwasher_1_off.webp",
        opened: "/images/assets/electronics/webp/dishwasher_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Fridge": {
    tag: "Electronics",
    width: 16, //in percentage
    defaultPosition: {
      x: 81, //in percentage
      y: 32, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/electronics/webp/fridge_1_off.webp",
        opened: "/images/assets/electronics/webp/fridge_1_on.webp",
      },
      red: {
        default: "/images/assets/electronics/webp/fridge_2_off.webp",
        opened: "/images/assets/electronics/webp/fridge_2_on.webp",
      },
      aqua: {
        default: "/images/assets/electronics/webp/fridge_3_off.webp",
        opened: "/images/assets/electronics/webp/fridge_3_on.webp",
      },
      lavender: {
        default: "/images/assets/electronics/webp/fridge_4_off.webp",
        opened: "/images/assets/electronics/webp/fridge_4_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Jug": {
    tag: "Electronics",
    width: 4, //in percentage
    defaultPosition: {
      x: 64, //in percentage
      y: 43, //in percentage
    },
    variants: {
      white: {
        default: "/images/assets/electronics/webp/kettle_1_off.webp",
        opened: "/images/assets/electronics/webp/kettle_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Kettle": {
    tag: "Electronics",
    width: 5, //in percentage
    defaultPosition: {
      x: 52.5, //in percentage
      y: 44, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/electronics/webp/kettle_2_off.webp",
        opened: "/images/assets/electronics/webp/kettle_2_on.webp",
      },
      gold: {
        default: "/images/assets/electronics/webp/kettle_3_off.webp",
        opened: "/images/assets/electronics/webp/kettle_3_on.webp",
      },
      aqua: {
        default: "/images/assets/electronics/webp/kettle_4_off.webp",
        opened: "/images/assets/electronics/webp/kettle_4_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Oven": {
    tag: "Electronics",
    width: 16, //in percentage
    defaultPosition: {
      x: 69, //in percentage
      y: 57, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/electronics/webp/oven_1_off.webp",
        opened: "/images/assets/electronics/webp/oven_1_on.webp"
      },
      red: {
        default: "/images/assets/electronics/webp/oven_2_off.webp",
        opened: "/images/assets/electronics/webp/oven_2_on.webp",
      },
      aqua: {
        default: "/images/assets/electronics/webp/oven_3_off.webp",
        opened: "/images/assets/electronics/webp/oven_3_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Pan": {
    tag: "Electronics",
    width: 8, //in percentage
    defaultPosition: {
      x: 39, //in percentage
      y: 53, //in percentage
    },
    variants: {
      grey: {
        default: "/images/assets/electronics/webp/pan_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Phone": {
    tag: "Electronics",
    width: 5, //in percentage
    defaultPosition: {
      x: 57, //in percentage
      y: 50, //in percentage
    },
    variants: {
      blue: {
        default: "/images/assets/electronics/webp/phone_hk.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Pot": {
    tag: "Electronics",
    width: 6.5, //in percentage
    defaultPosition: {
      x: 71, //in percentage
      y: 45, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/electronics/webp/pot_1_off.webp",
        opened: "/images/assets/electronics/webp/pot_1_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Tall Pot": {
    tag: "Electronics",
    width: 5, //in percentage
    defaultPosition: {
      x: 77, //in percentage
      y: 45, //in percentage
    },
    variants: {
      red: {
        default: "/images/assets/electronics/webp/pot_2_off.webp",
        opened: "/images/assets/electronics/webp/pot_2_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Television": {
    tag: "Electronics",
    width: 9, //in percentage
    defaultPosition: {
      x: 7.5, //in percentage
      y: 40, //in percentage
    },
    variants: {
      brown: {
        default: "/images/assets/electronics/webp/tv_hk_off.webp",
        opened: "/images/assets/electronics/webp/tv_hk_on.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
  "Vent": {
    tag: "Electronics",
    width: 23, //in percentage
    defaultPosition: {
      x: 67.5, //in percentage
      y: 0, //in percentage
    },
    variants: {
      silver: {
        default: "/images/assets/electronics/webp/vent_1.webp",
      },
    },
    isContainer: true,
    isLockable: true,
  },
};
